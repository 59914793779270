<template>
  <div class="card">
    <img
      class="card-img-top"
      src="@/assets/Images/Programmes/Enmascarar grupo 116.png"
      alt="Img-card"
    />
    <div class="card-cont">
      <div class="card-tit">
        <h2 class="card-title">
          {{ cardInfo.title }}
        </h2>
        <h5 class="card-subtitle">{{ cardInfo.subtitle }}</h5>
      </div>
      <div class="card-body">
        <div class="card-info" v-html="cardInfo.text"></div>
        <a class="btn btn-sc" href="">
          {{ cardInfo.button }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardInfo: Object,
  },
};
</script>

<style scoped>
.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 30px;
}

.card-img-top {
  border-radius: 30px 30px 0px 0px;
}

.card-cont {
  border: 1px solid #707070;
  border-top: none;
  border-radius: 0px 0px 30px 30px;
}

.card-tit {
  background: #0f1010 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 30px 30px;
  opacity: 1;
}

.card-title,
.card-subtitle {
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  color: #ffffff;
}

.card-title {
  padding-top: 25px;
  font: normal normal bold 38px/47px Montserrat;
  letter-spacing: 0px;
}

.card-subtitle {
  font: normal normal bold 38px/47px Montserrat;
  letter-spacing: 4.8px;
  padding-bottom: 15px;
}

.card-body {
  padding: 45px 100px;
}

.card-body >>> p.card-text {
  text-align: left;
  font: normal normal 500 32px/39px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #000000;
}

.card-body >>> p.card-text .promo {
  color: #fc202e;
}

.card-body >>> p.card-text .price {
  color: #9e9e9e;
  text-decoration: line-through;
}

.card-info {
  margin-bottom: 80px;
}

.btn-sc {
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  border: 3px solid #0f1010 !important;
  border-radius: 30px;
  font: normal normal bold 21px/25px Montserrat;
  color: #0f1010;
  padding: 5% 30%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
}

.btn-sc:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 20px;
}
</style>
