<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 title">
          <h2 data-aos="fade-right">
            {{locale[lang].PROGRAMMES.TITLE}}
          </h2>
        </div>
      </div>
      <div class="row" id="cards">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-for="(card, key) in cards" :key="key">
          <Card :cardInfo="card" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 end">
          <div class="row">
            <div class="col-12" id="first">
              <p>
                {{locale[lang].PROGRAMMES.COMMANDE.TEXT_1}}
              </p>
              <h5>
                {{locale[lang].PROGRAMMES.COMMANDE.TEXT_2}}
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12" id="second" data-aos="fade-right">
              <div class="img-sec">
                <img src="@/assets/Images/Programmes/Satisfaction.png" alt="satisfaction-img" />
              </div>
              <div class="text-sec">
                <p>
                  {{locale[lang].PROGRAMMES.END.TEXT_1}}
                </p>
                <p>
                  <i>{{locale[lang].PROGRAMMES.END.TEXT_2}}</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import Card from "@/components/ProgrammesCard.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  components: {
    NavBar,
    Footer,
    Card,
  },
  created() {
    this.cards = this.locale[this.lang].PROGRAMMES.CARDS
  },
  data() {
    return {
      cards: [],
    };
  },
};
</script>

<style scoped>
#cards {
  padding: 0 200px;
}

#nav-container, #footer-container {
  background-color: #0f1010!important;
}

.title {
  background-image: url("../assets/Images/Commencez-izi/Enmascarar grupo 130@2x.png");
  background-repeat: no-repeat;
  background-position: 0 -170px;
  background-size: 100% 150%;
  height: 300px;
}

.title h2 {
  text-align: center;
  font: normal normal bold 32px/39px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 2% 25%;
}

.end {
  background-image: url("../assets/Images/Programmes/Nos programmes especial.png");
  background-repeat: no-repeat;
  background-position: 42% 25px;
  background-size: auto;
  padding-top: 19%;
  padding-bottom: 5%;
}

.end p {
  font: normal normal 500 20px/35px Montserrat;
  letter-spacing: 0.4px;
}

#first p {
  text-align: center;
  color: #0f1010;
}

#second p {
  text-align: left;
  color: #ffffff;
  margin-bottom: 30px;
}

.img-sec {
  margin-left: 193px;
  margin-right: 50px;
}

.text-sec {
  margin-right: 13%;
}

.end h5 {
  text-align: center;
  font: normal normal bold 28px/35px Montserrat;
  letter-spacing: 0.56px;
  color: #0f1010;
  opacity: 1;
  margin-bottom: 290px;
}

#second {
  display: inline-flex;
}

@media (max-width: 1440px) {
  #cards {
    padding: 0 50px;
  }

  #cards >>> .card-body {
    padding: 30px 60px;
  }

  #cards >>> .card-body .btn-sc {
    padding: 5% 30%;
  }

  .end {
    padding-top: 25%;
  }

  .end h5 {
    margin-bottom: 15%;
  }
}

/* @media (max-width: 1280px) {
  .row.justify-content-center::after {
    top: 7%;
  }
} */

@media (max-width: 1024px) {
  .title {
    background-image: url("../assets/Images/Cycles/Enmascarar grupo 163.png");

    background-repeat: no-repeat;

    padding: 5% 5% 15% 5%;
    height: auto;
  }

  .title h2 {
    font: normal normal bold 18px/15px Montserrat;
    padding: 0 0 55px 0;
  }

  #cards {
    padding: 0;
  }

  #cards >>> .card {
    margin-bottom: 24px;
  }

  #cards >>> .card-cont {
    border: none;
    box-shadow: 0px 3px 15px #93939340;
  }

  #cards >>> .card-title {
    font: normal normal bold 18px/19px Montserrat;
  }

  #cards >>> .card-subtitle {
    font: normal normal bold 14px/16px Montserrat;
    letter-spacing: 1.95px;
  }

  #cards >>> .card-body {
    padding: 16px 26px;
  }

  #cards >>> .card-info {
    margin-bottom: 36px;
  }

  #cards >>> .card-body p.card-text {
    font: normal normal 600 16px/19px Montserrat;
  }

  #cards >>> .btn-sc {
    border-radius: 15px;
    font: normal normal bold 14px/18px Montserrat;
    padding: 17px 90px;
  }

  .end {
    background-image: url("../assets/Images/Programmes/Enmascarar grupo 222.png");
    background-repeat: no-repeat;
    background-position: 0 57px;
    background-size: 100% 100%;
    padding: 160px 26px 34px 26px;
  }

  .end p {
    text-align: center !important;
    font: normal normal 500 16px/24px Montserrat;
    letter-spacing: 0.26px;
    color: #090909;
  }

  .end p i {
    font-style: normal !important;
  }

  .end h5 {
    text-align: center;
    font: normal normal bold 16px/24px Montserrat;
    letter-spacing: 0.26px;
    color: #090909;
    margin: 0;
  }

  #second {
    display: block;
  }

  .img-sec {
    margin: 132px 0 24px 0;
  }

  .text-sec {
    margin: 0;
  }

  #second p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 425px) {
  .title {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -225px;
    padding: 0% 5% 10% 5%;
    height: auto;
  }
}

@media (max-width: 375px) {
  .title {
    background-size: auto;
    background-position: 0 -190px;
  }
}
</style>
