<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row" id="first">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <h1>{{ locale[lang].IDEES.TOP.TITLE }}</h1>
              <p>
                {{ locale[lang].IDEES.TOP.SUBTITLE }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row" id="cards">
                <div class="col-lg-4 col-md-12 col-sm-12" v-for="(link, key) in imgs" :key="key">
                  <img class="img-fluid" :src="link" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="faq">
        <div class="col-12">
          <div class="row justify-content-center exer" data-aos="fade-right">
            <div class="col-12 title">
              <h1>{{ locale[lang].IDEES.QUESTIONS.TITLE }}</h1>
              <h3 class="sub-red">{{ locale[lang].IDEES.QUESTIONS.SUBTITLE }}</h3>
            </div>
          </div>
          <div class="row items">
            <div class="col-12">
              <div class="row" v-for="(text, key) in locale[lang].IDEES.QUESTIONS.ITEMS" :key="key" data-aos="fade-right">
                <FaqItem :text="text" :img="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="guide">
        <div class="col-12">
          <div class="row guide">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <h3 v-html="locale[lang].IDEES.GUIDE.TITLE">
              </h3>
              <img class="img-fluid" src="@/assets/Images/Idees/Imagen 118@2x.png" alt="gif-a" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12" id="text">
              <p>
                 {{ locale[lang].IDEES.GUIDE.CONTENT.TEXT_1 }}
              </p>
              <p class="check" v-for="(check, key) in locale[lang].IDEES.GUIDE.CONTENT.CHECKS" :key="key">
                <i class="fas fa-check"></i> {{ check }}
              </p>
              <p>
                {{ locale[lang].IDEES.GUIDE.CONTENT.TEXT_2 }}
              </p>
              <p>
                {{ locale[lang].IDEES.GUIDE.CONTENT.TEXT_3 }}
              </p>
              <p>
                {{ locale[lang].IDEES.GUIDE.CONTENT.TEXT_4 }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="order">
        <div class="col-12">
          <div class="row" data-aos="fade-right">
            <div class="col-12 title">
              <h1>
                {{locale[lang].IDEES.COMMANDE.TITLE}}
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <p>
                {{locale[lang].IDEES.COMMANDE.CONTENT.TEXT_1}}
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <p>
                {{locale[lang].IDEES.COMMANDE.CONTENT.TEXT_2}}
              </p>
              <p>
                {{locale[lang].IDEES.COMMANDE.CONTENT.TEXT_3}}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h3>
                {{locale[lang].IDEES.COMMANDE.CONTENT.TEXT_4}}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="paypal">
        <ProductShop :programme="true" :change="locale[lang].CHANGE_G" :info="locale[lang].IDEES.INFOSHOP" />
      </div>

      <div class="row" id="satisfaction">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="garantie">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].GARANTIE_IMG)" alt="garantie" />
          <h2>{{ locale[lang].IDEES.GARANTIE.TITLE }}</h2>
          <p>
            {{ locale[lang].IDEES.GARANTIE.SUBTITLE }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="paiement">
          <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].PAY_IMG)" alt="paiement" />
          <h2>{{ locale[lang].IDEES.PAIEMENT.TITLE }}</h2>
          <p>
            {{ locale[lang].IDEES.PAIEMENT.SUBTITLE }}
          </p>
        </div>
        <div class="col-12" id="page">
          <h3>
            {{ locale[lang].IDEES.CONTACT.TEXT_1 }}
            <br />
            {{ locale[lang].IDEES.CONTACT.TEXT_2 }}
            <a href="javascript:;" @click="$router.push({ name: 'Contacto' })">{{ locale[lang].IDEES.CONTACT.LINK }}</a>
          </h3>
        </div>
      </div>

      <div class="row mb-5" id="exercices">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-12 title" data-aos="fade-right">
              <h1>{{ locale[lang].IDEES.EXERCICES.TITLE }}</h1>
            </div>
            <p>
              {{ locale[lang].IDEES.EXERCICES.SUBTITLE }}
            </p>
          </div>
          <div class="row justify-content-center" v-if="!mobile">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(e, key) in exercicesL" :key="key">
                <ExerciceCard :info="e" :id="'left-' + key" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(e, key) in exercicesR" :key="key">
                <ExerciceCard :info="e" :id="'right-' + key" />
              </div>
            </div>
          </div>
          <div class="row justify-content-center" v-else>
            <ExerciceCardsM :infoCards="infoCards" />
          </div>
        </div>
        <br />
      </div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
  import FaqItem from "@/components/FaqItem.vue";
  import ExerciceCard from "@/components/ExerciceCard.vue";
  import ExerciceCardsM from "@/components/ExerciceCardsM.vue";
  import ProductShop from "@/components/ProductShop.vue";
  import defaultMixin from "@/mixins/defaultMixin";
  import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";

  export default {
    mixins: [defaultMixin],
    components: {
      ProductShop,
      FaqItem,
      ExerciceCard,
      ExerciceCardsM,
      NavBar,
      Footer,
    },
    created() {
      this.exercicesL = this.locale[this.lang].IDEES.EXERCICES.E_LEFT;
      this.exercicesR = this.locale[this.lang].IDEES.EXERCICES.E_RIGHT;
    },
    computed: {
      infoCards() {
        return this.exercicesL.concat(this.exercicesR);
      },
    },
    data() {
      return {
        mobile: false,
        img: "information@2x-red.png",
        artInfo: {
          category: "Exemple",
        },
        imgs: [
          "https://da32ev14kd4yl.cloudfront.net/versioned/preparationphysiquefootball/RONDO101%20(2).png",
          "https://da32ev14kd4yl.cloudfront.net/versioned/preparationphysiquefootball/TACTIC8%20(3).png",
          "https://da32ev14kd4yl.cloudfront.net/versioned/preparationphysiquefootball/jeux-red111%20(2).png",
        ],
        exercicesL: [],
        exercicesR: [],
      };
    },
  };
</script>

<style scoped>
  #first {
    background-image: url("../assets/Images/Training/Trazado 2793.png"),
      url("../assets/Images/Training/Enmascarar grupo 174.png");
    background-size: auto;
    background-repeat: no-repeat;
    text-align: left;
    background-position: 70% 30%, 0 -115px;
    padding: 5% 10% 12% 10%;
  }

  #nav-container {
    background-color: #0f1010;
  }

  h1 {
    font: normal normal 800 38px/47px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
  }

  #first h1 {
    text-align: left;
    color: #ffffff;
    margin-bottom: 32px;
  }

  #first p {
    text-align: left;
    font: normal normal 500 26px/38px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 50px;
  }

  #cards img {
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .btn-sc {
    background-image: url("../assets/Images/Background/ball.png");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-color: #0f1010;
    border: 3px solid #ffffff !important;
    font: normal normal bold 22px/27px Montserrat;
    color: #ffffff;
    border-radius: 15px;
    padding: 24px 30px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background-size: 95px 80px;
    height: 85px;
  }

  .btn-sc:hover {
    background-color: rgba(209, 209, 209, 0.507);
    margin-left: 20px;
  }

  .title {
    position: relative;
  }

  #faq {
    background-image: url("../assets/Images/Idees/Trazado 2465.png"),
      url("../assets/Images/Idees/Elipse 117.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 8% 15%, 89% 63%;
    padding: 0% 15% 5% 15%;
  }

  .title::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 60px;
    right: 0;
    left: 0;
    margin: auto;
    bottom: -20px;
  }

  #faq .title::after,
  #order .title::after {
    bottom: -20px;
  }

  #faq .title {
    margin-bottom: 7%;
  }

  #faq .title h1 {
    text-align: center;
    color: #0f1010;
    margin-bottom: 20px;
  }

  #faq .title h3 {
    text-align: center;
    font: normal normal 500 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #fc202e;
    opacity: 1;
  }

  .items>>>.item {
    width: auto;
    padding: 20px 42px;
  }

  .items>>>h3 {
    font: normal normal 500 22px/30px Montserrat;
    letter-spacing: 0.44px;
    color: #242424;
    opacity: 1;
    white-space: initial;
  }

  #guide {
    background-image: url("../assets/Images/Idees/Enmascarar grupo 148.png");
    background-size: 100% 95%;
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 18% 10% 17% 10%;
  }

  #guide .btn-sc {
    margin-top: 65px;
  }

  #guide h2 {
    text-align: center;
    font: normal normal 600 32px/44px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    opacity: 1;
  }

  .guide {
    text-align: left;
  }

  .guide h3 {
    font: normal normal 500 28px/34px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .guide h3 >>> span {
    font-weight: 800;
  }

  .guide p {
    font: normal normal 500 22px/34px Montserrat;
    letter-spacing: 0.44px;
    color: #ffffff;
    opacity: 1;
  }

  .guide img {
    margin-top: 65px;
  }

  #stf {
    display: inline-flex;
    align-items: center;
  }

  #stf {
    margin: 16% 0 12% 0;
  }

  #stf .img-s {
    margin-right: 30px;
  }

  #stf p {
    text-align: left;
    font: normal normal 500 18px/35px Montserrat;
    letter-spacing: 0.36px;
    color: #ffffff;
    opacity: 1;
  }

  .fa-check {
    color: #f8394f;
  }

  #order {
    padding: 2% 15%;
  }

  #order h1 {
    color: #0f1010;
  }

  #order p {
    text-align: left;
    font: normal normal 500 22px/40px Montserrat;
    letter-spacing: 0.44px;
    color: #242424;
    opacity: 1;
    margin-bottom: 40px;
  }

  #order h3 {
    text-align: center;
    font: normal normal bold 28px/40px Montserrat;
    letter-spacing: 0.56px;
    color: #242424;
    opacity: 1;
    margin-top: 40px;
  }

  #order .title {
    margin-bottom: 5%;
  }

  #paypal {
    padding: 7% 12%;
  }

  #contact {
    padding: 14% 10%;
    background-image: url("../assets/Images/Idees/Enmascarar grupo 186.png");
    background-size: auto;
    background-repeat: no-repeat;
  }

  #contact h2 {
    text-align: center;
    font: normal normal 600 35px/50px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
  }

  #contact h2 a {
    color: #f9334a !important;
    text-decoration: none;
  }

  #exercices {
    padding: 0 10%;
    background-image: url("../assets/Images/Idees/Grupo 1042.png"),
      url("../assets/Images/Idees/Enmascarar grupo 150.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 91% 98%, 0 45%;
  }

  #exercices .title::after {
    bottom: 50px;
  }

  #exercices p {
    text-align: center;
    font: normal normal 500 24px/29px Montserrat;
    letter-spacing: 0px;
    color: #0f1010 !important;
    opacity: 1;
  }

  #exercices h1 {
    color: #0f1010;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  #exercices>>>.card-text {
    position: relative;
    margin-top: 60px;
  }

  #exercices>>>.card-text::before {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 60px;
    left: 0;
    top: -30px;
    opacity: 1;
  }

  #left-1>>>p,
  #right-0>>>p,
  #right-1>>>p {
    line-height: 30px !important;
    color: #060606 !important;
  }

  #satisfaction {
    background-image: url("../assets/Images/Practice/Enmascarar grupo 149.png"),
      url("../assets/Images/Cycles/Enmascarar grupo 149.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 0 0, 50% 100%;
    padding: 14% 0%;
  }

  #satisfaction #garantie {
    padding-left: 12%;
    padding-right: 6%;
    position: relative;
  }

  #satisfaction #garantie::after {
    content: "";
    position: absolute;
    height: 75%;
    left: 99%;
    top: 20%;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
  }

  #satisfaction #paiement {
    padding: 7% 8% 0 5%;
  }

  #satisfaction h2 {
    text-align: center;
    font: normal normal 800 30px/37px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    margin: 27px 0;
  }

  #satisfaction p {
    font: normal normal 500 22px/30px Montserrat;
    letter-spacing: 0.44px;
    text-align: center;
    color: #ffffff !important;
  }

  #page {
    padding: 8% 10% 0 10%;
  }

  #page h3 {
    text-align: center;
    font: normal normal 600 35px/50px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
    color: #ffffff;
  }

  #page h3 a {
    color: #f9334a;
  }

  @media (max-width: 1360px) {
    #guide {
      padding: 24% 5%;
    }

    .guide p {
      font-size: 20px;
      line-height: 30px;
    }

    #stf {
      margin: 16% 0 15% 0;
    }

    #stf p {
      font-size: 16px;
      line-height: 30px;
    }

    #order {
      padding: 2% 10%;
    }

    #paypal {
      padding: 7% 10%;
    }

    #contact {
      padding: 16% 10%;
    }

    .text-card {
      padding: 0 5%;
    }

    #exercices .title::after {
      bottom: 30px;
    }
  }

  @media (max-width: 1280px) {
    #stf {
      margin: 18% 0 16% 0;
    }
  }

  @media (max-width: 1024px) {
    #first {
      background-image: url("../assets/Images/Cycles/Enmascarar grupo 163.png");
      background-size: 100% 70%;
      background-repeat: no-repeat;
      background-position: 0 0;
      padding: 0% 5% 10% 5%;
      margin-bottom: 5%;
    }

    #first h1 {
      text-align: center;
      font-size: 18px;
      line-height: 20px;
    }

    #first p {
      text-align: center;
      font: normal normal 300 16px/18px Montserrat !important;
    }

    #first .row .col-12 {
      text-align: center;
    }

    .col-lg-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .btn-sc {
      font-size: 16px;
      line-height: 20px;
      padding: 12px 30px;
    }

    #faq {
      background-image: none;
      padding: 10px 15px;
    }

    .items>>>.item {
      padding: 11px 26px 11px 14px;
    }

    .items>>>.item img {
      height: 45px;
      width: 45px;
      margin-right: 16px;
    }

    .items>>>.item h3 {
      font-size: 16px !important;
      line-height: 18px !important;
      letter-spacing: 0.24px;
    }

    #guide {
      background-image: url("../assets/Images/Exercices/Enmascarar grupo 207.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      padding: 25% 5% 20% 5%;
    }

    .guide h3 {
      font-size: 20px;
      line-height: 22px;
    }

    .guide p {
      font-size: 16px;
      line-height: 18px;
    }

    .guide img {
      margin: 40px 0;
    }

    #guide .btn-sc {
      margin-top: 25px;
    }

    #guide h2 {
      font-size: 22px;
      line-height: 26px;
      color: #ffffff;
    }

    #satisfaction {
      background-image: url("../assets/Images/Cycles/Satisfaction Garantie.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
    }

    #satisfaction #garantie {
      padding: 0 6%;
      margin-bottom: 50px;
    }

    #satisfaction #garantie::after {
      border-right: 0;
    }

    #paiement img {
      max-width: 50%;
    }

    #paiement h2 {
      font-size: 18px;
      line-height: 26px;
    }

    #page {
      padding: 38% 9% 8%;
    }

    #page h3 {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.12px;
    }

    #page h3 br {
      display: none;
    }

    #stf {
      margin-top: 25%;
      display: block;
    }

    #stf .img-s {
      margin-right: 0;
      margin-bottom: 20px;
    }

    #stf p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      font-weight: 600;
    }

    #order {
      padding: 10% 2%;
    }

    #order .title {
      padding: 0;
      margin-bottom: 60px;
    }

    #order h1 {
      font-size: 20px;
      line-height: 22px;
    }

    #faq .title::after,
    #order .title::after {
      bottom: -10px;
    }

    #order .col-sm-12 {
      padding-left: 20px;
    }

    #order>>>p {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.24px;
      margin-bottom: 20px;
    }

    #order h3 {
      text-align: center;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0;
    }

    #paypal {
      padding: 7% 5%;
    }

    #satisfaction p {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.12px;
      margin-bottom: 5px;
    }

    #contact {
      background-image: url("../assets/Images/Exercices/Enmascarar grupo 208.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 -5px;
      height: auto;
      padding: 20% 11%;
    }

    #contact h2 {
      font: normal normal 500 16px/20px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
    }

    #exercices {
      background-image: none;
      margin-top: 60px;
      padding: 5% 7% 10% 7%;
    }

    #exercices .title {
      padding: 0;
      position: relative;
    }

    #exercices .title::after {
      bottom: 20px;
    }

    #exercices>>>.option h3 {
      font-size: 14px;
    }

    #exercices h1 {
      text-transform: initial;
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 22px;
    }

    #exercices p {
      text-align: center;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.12px;
      color: #151515 !important;
    }

    #exercices>>>.card-title {
      font-size: 15px;
      line-height: 16px;
    }

    #exercices>>>.card-text p {
      font-size: 14px;
    }

    #exercices>>>.row {
      justify-content: center;
    }
  }

  @media (max-width: 825px) {
    #first {
      background-size: 100%;
      margin-bottom: 0%;
    }
  }


  @media (max-width: 425px) {
    #card .btn-pp {
      padding: 0 33%;
      margin-left: 18px;
      margin-right: 22px;
      margin-bottom: 20px;
    }

    #card .btn-tc {
      padding: 15px 20%;
    }
  }

  @media (max-width: 375px) {
    #contact {
      background-size: auto;
      background-position: 50% 0;
      padding: 23% 5%;
    }

    #guide {
      padding: 25% 5%;
    }
  }
</style>